<template>
  <section class="pay_way mt30">
    <div class="header">支付方式</div>

    <div class="pay_ways mt30">
      <div class="pay_way_item" v-for="(item, index) in payWays" :key="index">
        {{item}}
        <div class="selected">
          <img src="~assets/images/selected.png">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      payWays: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .pay_way {
    .header {
      font-size: var(--fontSize16);
      color: var(--color000);
      font-weight: bold;
    }

    .pay_ways {
      .pay_way_item {
        position: relative;
        width: 150px;
        height: 50px;
        background: var(--colorfffbf7);
        border: 1px solid var(--colored6d00);
        font-size: var(--fontSize16);
        color: var(--colored6d00);
        font-weight: 500;
        text-align: center;
        line-height: 48px;
        .selected {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 38px;
          height: 37px;
        }
      }
    }
  }
</style>
