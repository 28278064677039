<template>
  <section class="choose_goods_address mt30 pb30">
    <div class="header frsbc">
      <div class="left">选择收货地址</div>
      <div class="right" @click="createGoodsAddress">+ 新增收货地址</div>
    </div>
    <div class="address_list frfsc">
      <div class="address_item mt30 p15" :class="{'avtive': currentIndex === index}" v-for="(item, index) in addressList" :key="item.id">
        <div @click="chooseAddress(index)">
          <div class="name_phone">
            <span class="mr15">
              {{item.linkMan}}
            </span>
            <span>
              {{item.linkTel}}
            </span>
          </div>
          <div class="address mt15">
            {{item.province}} {{item.city}} {{item.area}} {{item.addressDesc}}
          </div>
        </div>
        <div class="operation mt30 frsbc">
          <div class="left">
            <div class="default p5" v-if="item.isDefault">默认</div>
            <div class="no_default" v-else>
              <el-checkbox class="mr5" @change="setDefault(item.id, item.isDefault)"></el-checkbox>设为默认
            </div>
          </div>
          <div class="right frfsc">
            <div class="edit" @click="editGoodsAddress(item.id)">编辑</div>
            <div class="rod m05"></div>
            <el-popconfirm title="确认要删除该地址吗？" confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteGoodsAddress(item.id)">
              <div slot="reference" class="delete">删除</div>
            </el-popconfirm>
          </div>
        </div>
        <div class="selected" v-if="currentIndex === index">
          <img src="~assets/images/selected.png">
        </div>
      </div>
    </div>
    <CreateGoodsAddress ref="CreateGoodsAddress" :visible.sync="visible" :title="title" @determine="determineHandle" />
  </section>
</template>

<script>
  import CreateGoodsAddress from './CreateGoodsAddress.vue';
  import {
    createGoodsAddress,
    deleteGoodsAddress
  } from 'api/settlementPage.js';

  export default {
    components: {
      CreateGoodsAddress
    },

    props: {
      addressList: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        visible: false,
        title: '',
        currentIndex: 0
      }
    },

    methods: {
      chooseAddress(index) {
        this.currentIndex = index;
        this.$emit('chooseAddress', this.addressList[this.currentIndex].id);
      },
      createGoodsAddress() {
        this.visible = true;
        this.title = '新增收货地址';
      },
      editGoodsAddress(id) {
        this.$refs.CreateGoodsAddress.getGoodsAddress(id);
        this.visible = true;
        this.title = '编辑收货地址';
      },
      determineHandle() {
        this.currentIndex = 0;
        this.$emit('address');
      },
      setDefault(id, isDefault) {
        if(isDefault == 1) {
          return;
        }
        createGoodsAddress({
          id,
          isDefault: 1
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.$emit('address');
          this.cancelClick();
        });
      },
      deleteGoodsAddress(id) {
        deleteGoodsAddress({
          id
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$emit('address');
        });
      }
    },

    watch: {
      addressList: {
        handler(newValue) {
          newValue.forEach((item, index) => {
            if(item.isDefault) this.currentIndex = index;
          });
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .choose_goods_address {
    border-bottom: 8px solid var(--colorf2f2f2);
    .header {
      .left {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: bold;
      }
      .right {
        font-size: var(--fontSize14);
        color: var(--color1890ff);
        font-weight: bold;
        cursor: pointer;
      }
    }
    .address_list {
      flex-wrap: wrap;
      .address_item {
        position: relative;
        margin-right: 2%;
        width: 32%;
        background: var(--colorfbfbfb);
        border: 1px solid var(--colore6e6e6);
        cursor: pointer;
        &:nth-child(3n) {
          margin-right: 0 !important;
        }
        .name_phone {
          span {
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: bold;
          }
        }
        .address {
          font-size: var(--fontSize14);
          font-weight: 400;
          color: var(--color333);
        }
        .operation {
          .left {
            .default {
              background: var(--colorfcef84);
              border-radius: 4px;
              font-size: var(--fontSize12);
              color: var(--color591d01);
              font-weight: bold;
            }
            .no_default {
              font-size: var(--fontSize12);
              color: var(--color999);
              font-weight: 500;
            }
          }
          .right {
            div {
              font-size: var(--fontSize12);
              color: var(--color1890ff);
              font-weight: 500;
            }
            .rod {
              width: 1px;
              height: 10px;
              background-color: var(--color1890ff);
            }
          }
        }
        .selected {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 38px;
          height: 37px;
        }
      }
      .avtive {
        background: var(--colorfffbf7);
        border: 1px solid var(--colored6d00);
        .operation {
          .right {
            .edit, .delete {
              color: var(--colored6d00);
            }
            .rod {
              background-color: var(--colored6d00);
            }
          }
        }
      }
    }
  }
</style>
