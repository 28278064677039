<template>
  <section class="settlement_page pb50">
    <NavBar />
    <Header
      type="settlementPage"
    />
    <div class="content box pb150">
      <ChooseGoodsAddress
        ref="ChooseGoodsAddress"
        :addressList="addressList"
        @chooseAddress="chooseAddressHandle"
        @address="getUserAddressList"
      />
      <Table
        :tableData="tableData"
      />
      <Pagination
        :pageIndex.sync="paging.pageIndex"
        :pageSize.sync="paging.pageSize"
        :total="paging.total"
        @change="getShoppingList"
      />
      <PayAmount
        :payAmount="payAmount"
      />
      <!-- <PayWay
        :payWays="['扫码支付']"
      /> -->
      <Footer
        type="settlementPage"
        :addressId="currentAddressId"
        :count="payAmount.count"
        @goPayPage="goPayPage"
      />
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import ChooseGoodsAddress from './components/ChooseGoodsAddress.vue';
  import Table from './components/Table.vue';
  import Pagination from 'components/Pagination/index.vue';
  import PayAmount from './components/PayAmount.vue';
  import PayWay from './components/PayWay.vue';
  import Footer from 'components/Footer/index.vue';
  import {
    getUserAddressList,
    getShoppingList,
    getShoppingDetail
  } from 'api/settlementPage.js';

  export default {
    components: {
      NavBar,
      Header,
      ChooseGoodsAddress,
      Table,
      Pagination,
      PayAmount,
      PayWay,
      Footer
    },

    created() {
      this.getUserAddressList();
    },

    data() {
      return {
        addressList: [],
        currentAddressId: 0,
        tableData: [],
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        payAmount: {},
        btnFlag: true
      }
    },

    methods: {
      getUserAddressList() {
        getUserAddressList().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.length === 0) {
            this.$message.warning('请先填写地址！');
          }
          this.addressList = res.data;
          this.currentAddressId = this.addressList[0] ? this.addressList[0].id : null;
          this.getShoppingDetail();
        });
      },
      chooseAddressHandle(currentAddressId) {
        this.currentAddressId = currentAddressId;
        this.getShoppingDetail();
      },
      getShoppingList() {
        getShoppingList({
          addressId: this.currentAddressId,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          res.data.records.forEach(item => {
            this.$set(item, 'priceType', this.payAmount.priceType);
          });
          this.tableData = res.data.records;
          this.paging.total = res.data.total;
        });
      },
      getShoppingDetail() {
        getShoppingDetail({
          addressId: this.currentAddressId,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.btnFlag = false
            this.$message.error(res.message);
            return;
          }
          this.btnFlag = true
          this.payAmount = {
            price: res.data.price,
            activityDisMoney: res.data.activityDisMoney,
            fare: res.data.fare,
            count: res.data.count,
            priceType: res.data.priceType
          };
          this.getShoppingList();
        });
      },
      goPayPage(addressId) {
        if(!this.btnFlag) {
          this.$message.error('因特殊原因该地区暂不支持配送');
          return
        }
        for(let i = 0; i < this.tableData.length; i ++) {
          let validNum = this.tableData[i].validNum;
          let number = this.tableData[i].number;
          let goodsName = this.tableData[i].goodsName;
          if(validNum < number) {
            this.$message.warning(`${goodsName}商品库存不足！`);
            return;
          }
        }
        this.$router.replace({
          name: 'payPage',
          params: {
            addressId
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .settlement_page {

  }
</style>
