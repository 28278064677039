<template>
  <section class="procurement_listing mt30">
    <div class="header frsbc">
      <div class="left">采购清单</div>
      <div class="right" @click="goPurchaseOrder">返回采购单</div>
    </div>
    <el-table class="mt30" :data="tableData" border stripe>
      <el-table-column label="商品ISBN" prop="isbn" align="center"></el-table-column>
      <el-table-column label="商品编号" prop="goodsCode" align="center"></el-table-column>
      <el-table-column label="商品图片" prop="appGoodsSmPicUrl" align="center">
        <template slot-scope="scope">
          <img class="img" :src="scope.row.appGoodsSmPicUrl">
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="goodsName" align="center"></el-table-column>
      <el-table-column label="单价（元）" prop="priceType" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.priceType == 0 ? scope.row.basePrice : scope.row.priceType == 1 ? scope.row.extendPrice : scope.row.priceType == 3 ? scope.row.unionPrice : scope.row.tradePrice}}</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="number" align="center"></el-table-column>
      <el-table-column label="小计（元）" prop="payPrice" align="center">
        <template slot-scope="scope">
          <span>{{(scope.row.priceType == 0 ? scope.row.basePrice * scope.row.number : scope.row.priceType == 1 ? scope.row.extendPrice * scope.row.number : scope.row.priceType == 3 ? scope.row.unionPrice * scope.row.number : scope.row.tradePrice * scope.row.number).toFixed(2)}}</span>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
  export default {
    props: {
      tableData: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      regularFlag() {
        return this.$store.state.purchaseOrder.regularFlag;
      }
    },

    methods: {
      goPurchaseOrder() {
        this.$router.replace({
          name: 'purchaseOrder'
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .procurement_listing {
    .header {
      .left {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: bold;
      }
      .right {
        font-size: var(--fontSize14);
        color: var(--color1890ff);
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
</style>
