<template>
  <section class="pay_amount pb30">
    <div class="content mt15 frfec p150 pr15">
      <div class="label fcsafe">
        <p class="goods_total_amount">商品总金额：</p>
        <p class="activity_dis_money">优惠金额：</p>
        <p class="freight">运费：</p>
        <p class="combined_amount">合计金额：</p>
      </div>
      <div class="value fcsafe">
        <p class="goods_total_amount">{{payAmount.price}}元</p>
        <p class="activity_dis_money">{{payAmount.activityDisMoney}}元</p>
        <p class="freight">{{payAmount.fare}}元</p>
        <p class="combined_amount">{{payAmount.count && +payAmount.count.toFixed(2)}}元</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    payAmount: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
  .pay_amount {
    // border-bottom: 8px solid var(--colorf2f2f2);
    .content {
      width: 100%;
      height: 150px;
      background: var(--colorfbfbfb);
      border: 1px solid var(--colorccc);
    }
    .label, .value {
      height: 100%;
      text-align: right;
    }
    .value {
      .combined_amount {
        color: var(--colorfe1818);
      }
    }
    .goods_total_amount {
      font-size: var(--fontSize12);
      color: var(--color000);
      font-weight: 500;
    }
    .activity_dis_money {
      font-size: var(--fontSize12);
      color: var(--color000);
      font-weight: 500;
    }
    .freight {
      font-size: var(--fontSize12);
      color: var(--color000);
      font-weight: 500;
    }
    .combined_amount {
      font-size: var(--fontSize16);
      color: var(--color000);
      font-weight: bold;
    }
  }
</style>
